import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa"
import { FaMailBulk } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaPhone } from "react-icons/fa"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import TitleLogo from "../title-logo/title-logo";
import "./contact.scss"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3"
const ContactPage = () => {
    const { t } = useTranslation();
    const [token, setToken] = useState()
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        // you can use the FormData to send the form data to your API
        try {
            const response = await fetch("https://cmsv2.dact.studio/api/kbo/contact/add", {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                console.log("Form submitted successfully");
                setSubmissionStatus("success");
                e.target.reset();  // clear the form fields
            } else {
                console.error("Form submission failed");
                setSubmissionStatus("error");
            }
        } catch (error) {
            console.error("There was an error submitting the form:", error);
        }
    };

    return (
        <section className="ftco-section about-us mb-5">
            <TitleLogo siteTitle={"contact"} siteComment={<span><a href="/" ><Trans>home</Trans></a> </span>} />
            <div className="container" id="contact">

                <div className="col-lg-12">
                    <div className="wrapper">
                        <div className="row no-gutters">
                            <div className="col-md-6 d-flex align-items-stretch px-0">
                                <div className="contact-wrap w-100 p-md-5 p-4 py-5">
                                    <h3 className="mb-4"><Trans>write us</Trans></h3>
                                    <div id="form-message-success" className="mb-4">
                                        {submissionStatus === "success" && "Your message was sent, thank you!"}
                                    </div>
                                    <GoogleReCaptchaProvider reCaptchaKey="6LfZPR8hAAAAAJLXjHdY-v-ytu01I057xcT5ufGp">
                                        <form onSubmit={handleSubmit} id="contactForm" name="contactForm" className="contactForm">
                                            <div className="row" id="slide-button">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="name" id="name"
                                                            placeholder={t('name')} required />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" name="email" id="email"
                                                            placeholder={t('email')} required />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="subject"
                                                            id="subject" placeholder={t('subject')} required />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" className="form-control" id="message"
                                                            cols="30" rows="6" placeholder={t('message')} required></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="">

                                                        <GoogleReCaptcha
                                                            onVerify={token => {
                                                                setToken(token);
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="submit" value={t('sendMessage')}
                                                            className="btn btn-primary mt-3" />
                                                        <div className="submitting"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </GoogleReCaptchaProvider>

                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-stretch px-0">
                                <div className="info-wrap w-100 p-md-5 p-4 py-5 img">
                                    <h3><Trans>Contact information</Trans></h3>
                                    <p className="mb-4 text-white"><Trans>We're open for any suggestion or just to have a chat</Trans></p>
                                    <div className="dbox w-100 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center text-white">
                                            <FaPhone />
                                        </div>
                                        <div className="text pl-3">
                                            <p><span><Trans>Phone</Trans>:</span> <a href="tel:905389837634">+90 538 983 76 34</a></p>
                                        </div>
                                    </div>
                                    <div className="dbox w-100 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center text-white">
                                            <FaWhatsapp />
                                        </div>
                                        <div className="text pl-3">
                                            <p><span>Whatsapp: </span> <a
                                                href="https://wa.me/905389837634">+90 538 983 76 34</a></p>
                                        </div>
                                    </div>
                                    <div className="dbox w-100 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center text-white">
                                            <FaMailBulk />
                                        </div>
                                        <div className="text pl-3">
                                            <p><span><Trans>email</Trans>:</span> <a
                                                href="mailto:iletisim@kbomuhendislik.com">iletisim@kbomuhendislik.com</a></p>
                                        </div>
                                    </div>
                                    <div className="dbox w-100 d-flex align-items-start">
                                        <div className="icon d-flex align-items-center justify-content-center text-white">
                                            <FaMapMarkerAlt />
                                        </div>
                                        <div className="text pl-3">
                                            <p><span><Trans>Address</Trans>:</span> <a target="_blank" href="https://maps.google.com/maps/place/Tekstilkent+B13+Blok/@41.066361,28.866227,15z/data=!4m2!3m1!1s0x0:0x79365617a5736946?sa=X&ved=2ahUKEwjegszYjcb6AhWuVfEDHXw1BR8Q_BJ6BAhSEAU">Oruçreis mahallesi Tekstilkent Caddesi Tekstilkent Sitesi B13 Blok No:59 Esenler/İstanbul</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;