import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactPage from "../components/contact/contact";
import {graphql} from "gatsby";

const Contact = () => (
    <Layout>
        <Seo title="Contact" />
        <ContactPage/>
    </Layout>
)

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;